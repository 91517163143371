import React from 'react';
import { Pressable, View } from 'react-native';
import i18next from 'i18next';
import links from '@constants/links';
import { styles } from './styles';
import { useNavigation } from '@react-navigation/core';
import FastImage from '@atoms/FastImage';
import { isWebResponsive } from '@constants/platform';

const GameBuilderCreatedBanner = (): JSX.Element => {
  const { language } = i18next;
  const navigation = useNavigation();

  // functions
  function handleClick(): void {
    navigation.navigate('GameBuilder');
    return;
  }

  // constants
  const locale = language.toLocaleUpperCase();
  const deviceTag = isWebResponsive ? 'Mobile' : 'Desktop';

  const bannerImageUrl: string = links[`gameBuilderCreatedBanner${deviceTag}${locale}`];

  // render
  return (
    <View>
      <Pressable onPress={handleClick} style={[styles.container]}>
        <FastImage source={{ uri: bannerImageUrl }} style={styles.image} resizeMode="contain" />
      </Pressable>
    </View>
  );
};

export default GameBuilderCreatedBanner;
