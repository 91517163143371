import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useEffect, useRef } from 'react';
import { View } from 'react-native';
import Animated, { useAnimatedStyle, useSharedValue } from 'react-native-reanimated';
import { useFocusEffect } from '@react-navigation/core';
import { authTokenSelector, useStore } from '@store/index';

import { RootStackNavigator } from 'app/navigation/types';

import { styles } from './styles.web';

type GameBuilderPageProps = NativeStackScreenProps<RootStackNavigator, 'GameBuilder'>;

const GameBuilderPage = ({ navigation }: GameBuilderPageProps) => {
  const mainViewRef = useRef<HTMLIFrameElement | null>(null);

  const authToken = useStore(authTokenSelector);
  const headerAnimation = useSharedValue(0);
  const headerOpacityStyle = useAnimatedStyle(() => ({ opacity: headerAnimation.value }));

  const headerBackground = () => <Animated.View style={[headerOpacityStyle, styles.navbarBackground]} />;

  const sendUserAuthStatus = () => {
    const payload = JSON.stringify({ type: 'user-login', token: authToken });
    mainViewRef.current?.contentWindow?.postMessage(payload, 'https://gamebuilder.gato.us');
  };

  useEffect(() => {
    if (mainViewRef.current) sendUserAuthStatus();
  }, [authToken]);

  useFocusEffect(() => {
    navigation.setOptions({
      headerTransparent: true,
      headerBackground,
      title: `GATO | Game Builder`,
    });
  });

  // render
  window.addEventListener('message', (e) => {
    const { action, data } = e.data;

    if (action === 'navigate-login') {
      navigation.navigate('Login');
    }

    if (action === 'navigate-gamepage') {
      navigation.navigate('GamePage', { game: data.id, name: data.slug });
    }

    if (action === 'play-game') {
      navigation.navigate('GamePage', { game: data.id, name: data.slug, autoStartGame: true });
    }
  });

  const url = 'https://gamebuilder.gato.us/index.html';

  return (
    <View style={{ position: 'relative', flex: 1 }}>
      <iframe
        ref={mainViewRef}
        title="gato-game-builder"
        src={url}
        onLoad={sendUserAuthStatus}
        allow="clipboard-read; clipboard-write"
        style={{ width: '100%', height: '100%', border: 0 }}
        sandbox="allow-same-origin allow-scripts"
      />
    </View>
  );
};

export default GameBuilderPage;
