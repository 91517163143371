import { StyleSheet } from 'react-native';

import { moderateScale } from '@utils/scaling';

export const styles = StyleSheet.create({
  container: {
    marginBottom: 40,
    borderRadius: moderateScale(8),
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    height: 80,
    width: '100%',
  },
  image: {
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
  },
});
