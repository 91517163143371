/* eslint-disable react/no-unstable-nested-components */

import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { LinearGradient } from 'expo-linear-gradient';
import React, { createRef, useCallback, useEffect, useRef, useState } from 'react';
import {
  LayoutChangeEvent,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Pressable,
  ScrollView,
  View,
  Text
} from 'react-native';
import AnimatedLottieView from 'lottie-react-native';
import Animated, { useAnimatedStyle, useSharedValue, withSpring } from 'react-native-reanimated';
import { CommonActions, useFocusEffect, useNavigation } from '@react-navigation/core';
import { useScrollToTop } from '@react-navigation/native';

import SvgUri from '@atoms/SvgUri';
import FastImage from '@atoms/FastImage';
import MultiPlayerCatIcon from '@assets/svg/MultiPlayerCatIcon';
import SinglePlayerCatIcon from '@assets/svg/SinglePlayerCatIcon';
import VerifiedIcon from '@assets/svg/VerifiedIcon';
import Button from '@atoms/Button';
import CustomText from '@atoms/CustomText';
import { FooterButton } from '@atoms/FooterButton';
import Skeleton from '@atoms/Skeleton';
import { AnalyticsEvents } from '@constants/analyticsEvents';
import { HEADER_HEIGHT, isDesktop, isWebResponsive, SCREEN_WIDTH } from '@constants/platform';
import { useTranslations } from '@hooks/useTranslation';
import { CategoryData } from '@interfaces/explore';
import Controls from '@molecules/Controls';
import GoBackButton from '@molecules/GoBackButton';
import { Layout } from '@organisms/Layout';
import Slider from '@organisms/Slider';
import { useGameDetail } from '@services/useGameDetail';
import { authTokenSelector, setSelectedFiltersHandler, setShownAdHandler, useStore } from '@store/index';
import handleAnalyticsEvent from '@utils/analytics';
import { moderateScale } from '@utils/scaling';
import { RootStackNavigator } from 'app/navigation/types';
import { LikeCTAS } from '@molecules/LikeCTAS';
import { mediumVibration } from '@utils/vibration';
import SmallShareIcon from '@assets/svg/SmallShareIcon';
import share, { appURL } from '@utils/shareApp';
import Comments from '@organisms/Comments';
import SliderWeb from '@organisms/SliderWeb';
import { Colors, Spacing } from '@theme';
import { AvatarProfile } from '@atoms/AvatarProfile';
import { getLanguage } from '@utils/getLanguage';
import { useCountryIsoCode } from '@hooks/useCountryIsoCode';
import { BANNER_SIZE } from '@constants/sizes';
import AdImageComponent from '@organisms/AdComponent';
import GameQuestBanner from '@atoms/GameQuestBanner/index.web';
import { getGameSlug } from '@utils/common';
import useFeatureFlag, { gameQuestEventSelector } from '@store/featureFlag';
import useGameDescription from '@hooks/useGameDescription';
import { ControlTypeDesktopEnum, ControlTypeEnum } from 'app/enums/controlTypes.enum';
import { GoogleAdManagerUnitIdEnum } from '@atoms/GoogleAdManagerBanner/constants';
import AdVideo from '@molecules/AdVideo';

import { GameImage } from './GameImage/GameImage';
import { SensitiveMaterial } from './SensitiveBadge/SensitiveBadge';
import { lottie } from './utils';
import { styles } from './styles.web';
import { Ghost, ShareNetwork } from '@phosphor-icons/react';
import GameBuilderFilterLogo from '@assets/svg/GameBuilderFilterLogo';
import { reportUserActivity } from '@services/UserService';
import GameBuilderCreatedBanner from '@atoms/GameBuilderCreatedBanner';

type GamePageProps = NativeStackScreenProps<RootStackNavigator, 'GamePage'>;

const GamePage = ({ route, navigation }: GamePageProps) => {
  const { autoStartGame, from, name = '' } = route.params;

  // Refs
  const mainViewRef = createRef<ScrollView>();
  const animation = useRef<AnimatedLottieView>(null);

  // Hooks
  const isGGQ = useFeatureFlag(gameQuestEventSelector);
  const { getState } = useNavigation();
  const authToken = useStore(authTokenSelector);
  const setShownAd = useStore(setShownAdHandler);
  const headerAnimation = useSharedValue(0);
  const headerOpacityStyle = useAnimatedStyle(() => ({ opacity: headerAnimation.value }));
  const i18n = useTranslations();
  const countryISOCode = useCountryIsoCode();
  const { data: gameDetail, isLoading, error } = useGameDetail(name, countryISOCode);
  const setSelectedFilters = useStore(setSelectedFiltersHandler);
  const gameDescription = useGameDescription(gameDetail?.gameDto);
  useScrollToTop(mainViewRef);

  // States
  const [showPreroll, setShowPreroll] = useState(false);
  const [contentWidth, setContentWidth] = useState(
    isWebResponsive ? SCREEN_WIDTH - moderateScale(24) * 2 : undefined
  );
  const [likeByYou, setLikeByYou] = useState<any>(null);
  const [dislikeByYou, setDislikeByYou] = useState<any>(null);
  const [likesCount, setLikesCount] = useState<any>(null);
  const [likeData, setLikeData] = useState<any>(false);
  const language = getLanguage();

  const [allowSkip, setAllowSkip] = useState(false);
  const { routes } = getState();

  const headerBackground = () => <Animated.View style={[headerOpacityStyle, styles.navbarBackground]} />;

  // Effects
  useEffect(() => {
    navigation.setOptions({ headerShown: !showPreroll });
  }, [showPreroll]);

  useEffect(() => {
    if (gameDetail?.gameDto && likeByYou === null && dislikeByYou === null && likesCount === null) {
      setLikeByYou(gameDetail?.gameDto.likeByYou);
      setDislikeByYou(gameDetail?.gameDto.dislikeByYou);
      setLikesCount(gameDetail?.gameDto.likesCount);
      setLikeData(true);
    }
  }, [gameDetail, dislikeByYou, likeByYou, likesCount]);

  useFocusEffect(() => {
    const gameName = name || gameDetail?.gameDto.name;
    if (gameName === 'life%2C-the-game-of-chance') {
      navigation.navigate('Home');
    }

    navigation.setOptions({
      headerTransparent: true,
      headerBackground,
      title: `Game | ${gameDetail?.gameDto.name || name}`,
    });
  });

  // Variables
  const playersNumber = gameDetail?.gameDto ? gameDetail?.gameDto.numberOfPlayers : 1;

  // Functions
  const handlePlay = React.useCallback(() => {
    if (!gameDetail?.gameDto) return;

    const resourceLink = `${gameDetail?.gameDto.resource.link}/index.html`;
    navigation.navigate('GameWebView', {
      resourceLink,
      name: name || gameDetail?.gameDto.name,
      gameId: gameDetail?.gameDto.id.toString(),
    });
    navigation.setOptions({ headerShown: false });
    setShowAd(false);
    setAllowSkip(false);
  }, [
    gameDetail?.gameDto.resource.link,
    gameDetail?.gameDto.id,
    gameDetail?.gameDto.name,
    gameDetail?.gameDto.advertisingVideo,
  ]);

  const setShowAd = (value: boolean) => {
    setShowPreroll(value);
    setShownAd(value);
  };

  const handlePress = () => {
    reportUserActivity(gameDetail?.gameDto.id);
    navigation.setOptions({ headerShown: false });
    window.setTimeout(() => setAllowSkip(true), 6000);
    setShowAd(true);
  };

  const handleCategoryPress = (item: CategoryData, category: string) => {
    setSelectedFilters([{ ...item, category }]);
    navigation.navigate('Explore', { fromDetail: gameDetail?.gameDto.id });
  };

  const handleBack = useCallback(() => {
    navigation.setOptions({ headerShown: true });
    setShowAd(false);
  }, [navigation]);

  function handleGoBack(): void {
    const prevScreen = routes[routes.length - 2];

    if (from === 'explore') {
      navigation.push('Explore', { fromDetail: undefined });
      return;
    }

    // If the game page is the first screen in the stack, we push the home screen
    if (routes.length === 2 && routes[0].name === prevScreen.name) {
      navigation.push('Home');
      return;
    }

    if (routes.length > 1 && prevScreen.name === 'GamePage') {
      const navigateAction = CommonActions.navigate({
        name: 'GamePage',
        params: {
          name: prevScreen.params.name,
        },
        key: `GamePage/${prevScreen.params.name}`,
      });

      navigation.dispatch(navigateAction);
      return;
    }

    navigation.push('Home');
  }

  function onShare(): void {
    const sharedLink = `${appURL}/game/${name}`;

    // const message = `${i18n.t('game_page.share_game_1')}${gameDetail?.gameDto.name || name || ''}${i18n.t(
    //   'game_page.share_game_2'
    // )}${sharedLink || ''}`;
    share(sharedLink);
  }

  const onPressShare = () => {
    mediumVibration();
    animation?.current?.play();
  };

  const handleContentScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const offset = event.nativeEvent.contentOffset.y;
    if (offset && !headerAnimation.value) headerAnimation.value = withSpring(1);
    if (!offset && headerAnimation.value) headerAnimation.value = withSpring(0);
  };

  const handleContentLayout = (event: LayoutChangeEvent) => {
    if (isDesktop) setContentWidth(event.nativeEvent.layout.width);
  };

  const handleUser = async () => {
    const studioId = gameDetail?.gameDto?.studio?.userName;
    const navigateAction = CommonActions.navigate({
      name: 'UserProfile',
      params: {
        userName: studioId,
      },
      key: `UserProfile-${studioId}`,
    });
    navigation.dispatch(navigateAction);
  };

  const getMidAdDimensions = () => {
    if (isWebResponsive) {
      // Is mobile view
      return {
        widht: BANNER_SIZE.GAME_BANNER_MOBILE.WIDTH,
        height: BANNER_SIZE.GAME_BANNER_MOBILE.HEIGHT,
        googleAdManagerUnitId: GoogleAdManagerUnitIdEnum.GAME_BANNER_320_X_100_1,
      };
    }
    return {
      widht: BANNER_SIZE.GAME_BANNER_LARGE_DESKTOP.WIDTH,
      height: BANNER_SIZE.GAME_BANNER_LARGE_DESKTOP.HEIGHT,
      googleAdManagerUnitId: GoogleAdManagerUnitIdEnum.GAME_BANNER_728_X_90_1,
    };
  };

  const getFirstLateralAdDimensions = () => {
    if (isWebResponsive) {
      // Is mobile view
      return {
        widht: BANNER_SIZE.GAME_BANNER_SMALL_DESKTOP.WIDTH,
        height: BANNER_SIZE.GAME_BANNER_SMALL_DESKTOP.HEIGHT,
        googleAdManagerUnitId: GoogleAdManagerUnitIdEnum.GAME_BANNER_300_X_250_2,
      };
    }
    return {
      widht: BANNER_SIZE.GAME_LATERAL_BANNER_DESKTOP.WIDTH,
      height: BANNER_SIZE.GAME_LATERAL_BANNER_DESKTOP.HEIGHT,
      googleAdManagerUnitId: GoogleAdManagerUnitIdEnum.GAME_BANNER_300_X_600_1,
    };
  };

  const getSecondLateralAdDimensions = () => {
    if (isWebResponsive) {
      // Is mobile view
      return {
        widht: BANNER_SIZE.GAME_BANNER_SMALL_DESKTOP.WIDTH,
        height: BANNER_SIZE.GAME_BANNER_SMALL_DESKTOP.HEIGHT,
        googleAdManagerUnitId: GoogleAdManagerUnitIdEnum.GAME_BANNER_300_X_250_1,
      };
    }
    return {
      widht: BANNER_SIZE.GAME_BANNER_SMALL_DESKTOP.WIDTH,
      height: BANNER_SIZE.GAME_BANNER_SMALL_DESKTOP.HEIGHT,
      googleAdManagerUnitId: GoogleAdManagerUnitIdEnum.GAME_BANNER_300_X_250_1,
    };
  };

  useEffect(() => {
    if (error) {
      navigation.push('NotFound');
    }
  }, [error]);

  // start game automatically logic
  useEffect(() => {
    if (autoStartGame) {
      handlePress();
    }
  }, [autoStartGame]);

  // render
  return (
    <View style={{ position: 'relative', flex: 1 }}>
      {isWebResponsive && !showPreroll ? (
        <FooterButton
          style={{
            zIndex: 50,
            position: 'absolute',
            width: '100%',
            bottom: 0,
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Button
            customStyle={[styles.footerButton, { width: '100%' }]}
            customTextStyle={styles.footerButtonText}
            onPress={handlePress}>
            {i18n.t('game_page.play_game')}
          </Button>
        </FooterButton>
      ) : null}
      <Layout
        customRef={mainViewRef}
        padding={isDesktop}
        paddingTop={0}
        withScroll
        hasBottomCTAS={
          <FooterButton>
            <Button
              customStyle={styles.footerButton}
              customTextStyle={styles.footerButtonText}
              onPress={handlePress}>
              {i18n.t('game_page.play_game')}
            </Button>
          </FooterButton>
        }
        showScrollIndicator={false}
        desktopFooter
        onScroll={handleContentScroll}
        scrollEventThrottle={32}>
        {/* Commented styles are for the header banner */}
        {/* <View style={[styles.headerLeft, { paddingTop: getAdHeaderBannerHeight() }]}> */}
        <View style={[styles.headerLeft]}>
          <GoBackButton customStyles={styles.backBtn} goBackAction={handleGoBack} gatoLogo={false} />
        </View>
        {isWebResponsive ? (
          <View style={[styles.headerRight]}>
            <Pressable onPress={onShare}>
              <View style={styles.shareButton}>
                {/* <AnimatedLottieView
                  autoPlay={false}
                  ref={animation}
                  style={styles.lottie}
                  source={lottie}
                  loop={false}
                  onAnimationFinish={onShare}
                /> */}
                <ShareNetwork size={24} color={Colors.white} />
              </View>
            </Pressable>
          </View>
        ) : null}
        <View onLayout={handleContentLayout} />
        <Pressable
          onPress={() => {
            handlePress();
            handleAnalyticsEvent(AnalyticsEvents.OPEN_GAME_FROM_IMAGE);
          }}
          // Commented styles are for the header banner
          // style={[styles.desktopHeader, isWebResponsive ? { marginTop: BANNER_SIZE.HEADER_BANNER_MOBILE.HEIGHT + Spacing.SMALL } : { marginTop: BANNER_SIZE.GAME_BANNER_LARGE_DESKTOP.HEIGHT + Spacing.BIG }]}>
          style={[
            isDesktop && styles.desktopHeader,
            {
              height: isWebResponsive
                ? BANNER_SIZE.GAME_BANNER_MOBILE.HEIGHT * 5
                : BANNER_SIZE.GAME_BANNER_LARGE_DESKTOP.HEIGHT * 9,
              marginTop: isWebResponsive ? HEADER_HEIGHT : 0,
            },
          ]}>
          <LinearGradient
            start={{ x: 1, y: 0 }}
            end={{ x: 1, y: 1 }}
            locations={[0.5, 1]}
            colors={
              isWebResponsive ? ['rgba(0,0,0,0)', 'rgba(0,0,0,0.6)'] : ['rgba(0,0,0,0.3)', 'rgba(0,0,0,0.3)']
            }
            style={styles.linear}
          />
          <FastImage
            style={styles.headImage}
            source={{
              uri:
                isDesktop && !isWebResponsive
                  ? gameDetail?.gameDto.principalImage_web
                  : gameDetail?.gameDto.principalImage || '',
            }}
            resizeMode="cover"
          />
          <View style={isWebResponsive ? styles.headInfo : styles.headInfoDesktop}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: moderateScale(8),
              }}>
              {/* {isWebResponsive && <SensitiveMaterial violent={gameDetail?.gameDto.sensitiveMaterial} />} */}
              {isDesktop && !isLoading && !isWebResponsive ? (
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
                  <View style={styles.genre}>
                    <CustomText size="lmedium">{i18n.t(gameDetail?.gameDto.genre.translate || '')}</CustomText>
                  </View>
                  {gameDetail?.gameDto.isGameBuilder && (
                    <View style={{ width: '250px', height: '40px' }}>
                      <GameBuilderFilterLogo width="100%" height="auto" />
                    </View>
                  )}
                </View>
              ) : null}
              {isWebResponsive && <SensitiveMaterial violent={gameDetail?.gameDto.sensitiveMaterial} />}
            </View>
            <CustomText
              customStyle={[styles.headTitle, { paddingBottom: isWebResponsive ? 20 : 0 }]}
              size="xlarge"
              weight="interBold"
              align={isWebResponsive ? 'left' : 'center'}>
              {`${gameDetail?.gameDto.name || ''}  `}
              {isDesktop && !isWebResponsive ? (
                <SensitiveMaterial violent={gameDetail?.gameDto.sensitiveMaterial} />
              ) : null}
            </CustomText>
            {isDesktop && !isWebResponsive ? (
              <CustomText
                size={isWebResponsive ? 'lmedium' : 'big'}
                weight="interMedium"
                align="left"
                customStyle={styles.description}>
                {gameDescription[language]}
              </CustomText>
            ) : null}
            {isDesktop && !isWebResponsive ? (
              <View style={styles.headerButtons}>
                <Button
                  customStyle={styles.footerButton}
                  customTextStyle={styles.footerButtonText}
                  onPress={handlePress}>
                  {i18n.t('game_page.play_game')}
                </Button>
                {!isWebResponsive ? (
                  <Button
                    customStyle={styles.footerButton}
                    customTextStyle={styles.footerButtonText}
                    onPress={onShare}
                    terciary>
                    <SmallShareIcon height={17} scale={0.75} style={styles.buttonIcon} />
                    {`  ${i18n.t('game_page.share')}`}
                  </Button>
                ) : null}
              </View>
            ) : null}
          </View>
        </Pressable>
        {isLoading ? (
          <View style={styles.body}>
            <View style={styles.bodyLeft}>
              <View style={[styles.gameRow, styles.skeletonImg]}>
                <Skeleton
                  customStyle={[styles.skeletonText, { borderRadius: moderateScale(45) / 2 }]}
                  width={moderateScale(45)}
                  height={moderateScale(45)}
                />
                <Skeleton width={moderateScale(100)} height={moderateScale(30)} />
              </View>
              <Skeleton
                customStyle={styles.skeletonImg}
                width={SCREEN_WIDTH * 0.55}
                height={moderateScale(100)}
              />
            </View>
          </View>
        ) : (
          <View
            style={[
              styles.body,
              {
                position: 'relative',
                paddingTop: isWebResponsive ? moderateScale(15) : moderateScale(55),
              },
            ]}>
            <View style={contentWidth && contentWidth >= 900 ? styles.bodyLeft : styles.separator}>
              {isGGQ ? (
                <View>
                  <GameQuestBanner
                    gameId={gameDetail?.gameDto.id || 0}
                    gameSlug={getGameSlug(gameDetail?.gameDto.name || '')}
                  />
                </View>
              ) : null}
              {gameDetail?.gameDto.isGameBuilder ? (
                <View>
                  <GameBuilderCreatedBanner />
                </View>
              ) : null}
              {isWebResponsive ? (
                <View
                  style={{
                    width: 'min-content',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}>
                  <View
                    style={{
                      paddingVertical: moderateScale(6),
                      paddingHorizontal: moderateScale(8),
                      marginRight: moderateScale(12),
                      borderRadius: moderateScale(30),
                      backgroundColor: Colors.purple,
                    }}>
                    <CustomText size="msmall" numberOfLines={1}>
                      {i18n.t(gameDetail?.gameDto.genre.translate || '')}
                    </CustomText>
                  </View>
                  {/* {gameDetail?.gameDto.isGameBuilder && (
                    <View style={{ width: '115px', height: '30px' }}>
                      <GameBuilderFilterLogo width="100%" height="auto" />
                    </View>
                  )} */}
                </View>
              ) : null}
              {isWebResponsive ? (
                <CustomText
                  size="lmedium"
                  weight="interMedium"
                  align="left"
                  customStyle={styles.description}
                  color="greyThree">
                  {gameDescription[language]}
                </CustomText>
              ) : null}
              <Pressable style={styles.gameRow} onPress={handleUser}>
                {!isWebResponsive ? (
                  <View style={styles.avatar}>
                    <View>
                      <AvatarProfile
                        customContainerStyle={styles.creatorImg}
                        imageUrl={gameDetail?.gameDto.studio.avatar ?? ''}
                        imageUrlPlaceholder={gameDetail?.gameDto.studio.profileImagePlaceholder}
                        offsetKitten={5}
                      />
                      {gameDetail?.gameDto.isGameBuilder ? (
                        <View
                          style={{
                            position: 'absolute',
                            top: 0,
                            right: '6px',
                            zIndex: 3,
                            backgroundColor: Colors.black,
                            borderRadius: '50%',
                            padding: 1,
                          }}>
                          <Ghost weight="fill" color="#9746ff" />
                        </View>
                      ) : (
                        <VerifiedIcon
                          style={styles.verified}
                          fill={gameDetail?.gameDto.isGameBuilder ? Colors.purple : '#387cff'}
                        />
                      )}
                    </View>
                    <View style={styles.made}>
                      <CustomText align="left" customStyle={styles.madeBy} size="msmall" color="greyThree">
                        {i18n.t('game_page.made_by')}
                      </CustomText>
                      <CustomText align="left" customStyle={{ maxWidth: '25ch' }} numberOfLines={1}>
                        {gameDetail?.gameDto.studio.name}
                      </CustomText>
                    </View>
                  </View>
                ) : null}

                {isDesktop ? (
                  <>
                    <View style={styles.separator} />
                    {likeData ? (
                      <LikeCTAS
                        likeByYou={gameDetail?.gameDto.likeByYou}
                        dislikeByYou={gameDetail?.gameDto.dislikeByYou}
                        likesCount={gameDetail?.gameDto.likesCount}
                        // setLikeByYou={setLikeByYou}
                        // setDislikeByYou={setDislikeByYou}
                        // setLikesCount={setLikesCount}
                        gameId={gameDetail?.gameDto.id || 0}
                        navigation={navigation}
                        authToken={authToken}
                      />
                    ) : null}
                  </>
                ) : null}
              </Pressable>
              {isWebResponsive ? (
                <Pressable onPress={handleUser}>
                  <View style={[styles.avatar, { marginVertical: 30 }]}>
                    <View>
                      <AvatarProfile
                        customContainerStyle={styles.creatorImg}
                        imageUrl={gameDetail?.gameDto.studio.avatar ?? ''}
                        imageUrlPlaceholder={gameDetail?.gameDto.studio.profileImagePlaceholder}
                        offsetKitten={5}
                      />
                      {gameDetail?.gameDto.isGameBuilder ? (
                        <View
                          style={{
                            position: 'absolute',
                            top: 0,
                            right: '6px',
                            zIndex: 3,
                            backgroundColor: Colors.black,
                            borderRadius: '50%',
                            padding: 1,
                          }}>
                          <Ghost weight="fill" color="#9746ff" />
                        </View>
                      ) : (
                        <VerifiedIcon
                          style={styles.verified}
                          fill={gameDetail?.gameDto.isGameBuilder ? Colors.purple : '#387cff'}
                        />
                      )}
                    </View>
                    <View style={styles.made}>
                      <CustomText align="left" customStyle={styles.madeBy} size="msmall" color="greyThree">
                        {i18n.t('game_page.made_by')}
                      </CustomText>
                      <CustomText align="left" customStyle={{ maxWidth: '25ch' }} numberOfLines={1}>
                        {gameDetail?.gameDto.studio.name}
                      </CustomText>
                    </View>
                  </View>
                </Pressable>
              ) : null}

              {gameDetail?.gameDto ? (
                <Controls
                  type={
                    (isWebResponsive && gameDetail?.gameDto.controlTypeDesktop) ===
                    ControlTypeDesktopEnum.BOTH
                      ? ControlTypeEnum.TAP
                      : gameDetail.gameDto.controlTypeDesktop
                  }
                />
              ) : null}

              <View style={[styles.categories]}>
                <Pressable
                  onPress={() =>
                    handleCategoryPress(gameDetail?.gameDto.moods[0] as CategoryData, 'moodsIds')
                  }
                  style={styles.category}>
                  {gameDetail?.gameDto.moods[0].principalImage ? (
                    <SvgUri
                      uri={gameDetail?.gameDto.moods[0].principalImage}
                      style={isDesktop ? styles.categoryIcon : undefined}
                    />
                  ) : null}
                  <CustomText
                    numberOfLines={2}
                    customStyle={styles.categoryText}
                    weight="interBold"
                    color="greySix"
                    size={isDesktop && !isWebResponsive ? 'big' : 'medium'}>
                    {i18n.t(gameDetail?.gameDto.moods[0].translate || '')}
                  </CustomText>
                </Pressable>
                <Pressable
                  onPress={() =>
                    handleCategoryPress(gameDetail?.gameDto.graphic as CategoryData, 'graphicIds')
                  }
                  style={styles.category}>
                  {gameDetail?.gameDto.graphic.image && gameDetail?.gameDto.graphic.image.includes('.png') ? (
                    <FastImage
                      source={{ uri: gameDetail?.gameDto.graphic.image }}
                      style={isDesktop ? styles.categoryIcon : undefined}
                    />
                  ) : (
                    <SvgUri
                      uri={gameDetail?.gameDto.graphic.image || ''}
                      style={isDesktop ? styles.categoryIcon : undefined}
                    />
                  )}
                  <CustomText
                    numberOfLines={2}
                    customStyle={styles.categoryText}
                    weight="interSemi"
                    color="greyThree"
                    size={isDesktop && !isWebResponsive ? 'big' : 'medium'}>
                    {gameDetail?.gameDto.graphic.name}
                  </CustomText>
                </Pressable>
                <Pressable
                  onPress={() =>
                    handleCategoryPress(
                      {
                        id: playersNumber,
                        name:
                          playersNumber > 1
                            ? i18n.t('game_page.players').replace('_', playersNumber.toString())
                            : i18n.t('game_page.one_player'),
                      },
                      'players'
                    )
                  }
                  style={styles.category}>
                  {playersNumber > 1 ? <MultiPlayerCatIcon /> : <SinglePlayerCatIcon />}
                  <CustomText
                    numberOfLines={2}
                    customStyle={styles.categoryText}
                    weight="interSemi"
                    color="greyThree"
                    size={isDesktop && !isWebResponsive ? 'big' : 'medium'}>
                    {playersNumber > 1
                      ? i18n.t('game_page.players').replace('_', playersNumber.toString())
                      : i18n.t('game_page.one_player')}
                  </CustomText>
                </Pressable>
              </View>

              {/* <View style={[styles.addContainer, { marginTop: Spacing.LARGE, marginBottom: Spacing.LARGE }]}>
                <AdImageComponent
                  width={getMidAdDimensions().widht}
                  height={getMidAdDimensions().height}
                  analyticsEvent={AnalyticsEvents.AD_GAME_PAGE_CLICK}
                  creatorId={gameDetail?.gameDto.studio.user || 0}
                  googleAdManagerUnitId={getMidAdDimensions().googleAdManagerUnitId}
                />
              </View> */}
              {gameDetail?.gameDto.images.length && contentWidth && !gameDetail?.gameDto.isGameBuilder ? (
                <Slider
                  directSale={false}
                  innerContainerStyles={styles.slider}
                  titleStyles={styles.slider}
                  banners={gameDetail?.gameDto.images || []}
                  bannerDimentions={{
                    width: isWebResponsive ? moderateScale(308) : 319,
                    height: isWebResponsive ? moderateScale(280) : 290,
                  }}
                  customSlide={(item) => <GameImage image={item.image} />}
                  bannersGap={isWebResponsive ? 16 : undefined}
                  title={i18n.t('game_page.game_previews').toString()}
                  arrows={isDesktop}
                />
              ) : null}
              {/* {contentWidth && contentWidth < 900 ? (
                <View style={[styles.addContainer, { marginBottom: Spacing.LARGE }]}>
                  <AdImageComponent
                    width={getFirstLateralAdDimensions().widht}
                    height={getFirstLateralAdDimensions().height}
                    analyticsEvent={AnalyticsEvents.AD_GAME_PAGE_CLICK}
                    creatorId={gameDetail?.gameDto.studio.user || 0}
                    googleAdManagerUnitId={getFirstLateralAdDimensions().googleAdManagerUnitId}
                  />
                </View>
              ) : null} */}
              {gameDetail?.gameDto.gamesMightAlsoLike && contentWidth && contentWidth < 900 ? (
                <Slider
                  directSale={false}
                  might
                  containerStyles={styles.slider}
                  titleStyles={styles.slider}
                  qtyToSlide={1}
                  bannerDimentions={{
                    width: moderateScale(147),
                    height: moderateScale(190),
                  }}
                  title={i18n.t('game_page.also_like') || ''}
                  banners={gameDetail?.gameDto.gamesMightAlsoLike?.slice(0, 5)}
                  arrows={isDesktop}
                />
              ) : null}
              <Comments gameId={gameDetail?.gameDto.id || 0} handleComment={() => undefined} />
              {/* {contentWidth && contentWidth < 900 ? (
                <View style={[styles.addContainer, { marginTop: Spacing.LARGE }]}>
                  <AdImageComponent
                    width={getSecondLateralAdDimensions().widht}
                    height={getSecondLateralAdDimensions().height}
                    analyticsEvent={AnalyticsEvents.AD_GAME_PAGE_CLICK}
                    creatorId={gameDetail?.gameDto.studio.user || 0}
                    googleAdManagerUnitId={getSecondLateralAdDimensions().googleAdManagerUnitId}
                  />
                </View>
              ) : null} */}
            </View>
            {contentWidth && contentWidth >= 900 ? (
              <View style={contentWidth && contentWidth >= 900 ? styles.bodyRight : undefined}>
                {/* <View style={[styles.addContainer, { marginBottom: Spacing.LARGE }]}>
                  <AdImageComponent
                    width={getFirstLateralAdDimensions().widht}
                    height={getFirstLateralAdDimensions().height}
                    analyticsEvent={AnalyticsEvents.AD_GAME_PAGE_CLICK}
                    creatorId={gameDetail?.gameDto.studio.user || 0}
                    googleAdManagerUnitId={getFirstLateralAdDimensions().googleAdManagerUnitId}
                  />
                </View> */}
                {gameDetail?.gameDto.gamesMightAlsoLike ? (
                  <SliderWeb games={gameDetail?.gameDto.gamesMightAlsoLike?.slice(0, 5)} />
                ) : null}
                {/* <View style={[styles.addContainer, { marginTop: Spacing.LARGE }]}>
                  <AdImageComponent
                    width={getSecondLateralAdDimensions().widht}
                    height={getSecondLateralAdDimensions().height}
                    analyticsEvent={AnalyticsEvents.AD_GAME_PAGE_CLICK}
                    creatorId={gameDetail?.gameDto.studio.user || 0}
                    googleAdManagerUnitId={getSecondLateralAdDimensions().googleAdManagerUnitId}
                  />
                </View> */}
              </View>
            ) : null}
          </View>
        )}
      </Layout>
      {showPreroll && (
        <View style={{ backgroundColor: Colors.blackTwo, width: '100vw', height: '100vh', display: 'flex'}}>
          <View style={{ position: 'absolute', top: '30px', right: '30px', width: '160px', height: '80px', zIndex: 10000001 }}>
            <Pressable onPress={handlePlay} style={{ backgroundColor: allowSkip ? "#E5F68C" : Colors.yellowLight, display: 'flex', justifyContent: 'center', alignItems: 'center', paddingVertical: moderateScale(16), paddingHorizontal: moderateScale(18), borderRadius: '10px' }}>
              <Text style={{ fontWeight: 'bolder' }}>{ allowSkip ? "Play now!" : "Loading game..." }</Text>
            </Pressable>
          </View>
          <AdVideo
            handleBack={handleBack}
            handlePlay={handlePlay}
            gatoDirectSaleVideo={
              isWebResponsive
                ? gameDetail?.gameDto.advertisingVideo.MOBILE
                : gameDetail?.gameDto.advertisingVideo.DESKTOP
            }
          />
        </View>
      )}
    </View>
  );
};

export default GamePage;
